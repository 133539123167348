import { PureComponent } from 'react';

import Html from 'Component/Html';
import { ReactElement } from 'Type/Common.type';

import { ProductCardBannerComponentProps } from './ProductCardBanner.type';

import './ProductCardBanner.style';

/** @namespace PlugAndSell2/Component/ProductCardBanner/Component */
export class ProductCardBannerComponent extends PureComponent<ProductCardBannerComponentProps> {
    render(): ReactElement {
        const { banner, layout } = this.props;

        return (
            <li block="ProductCardBanner" mix={{ block: 'Banner', mods: { layout } }}>
                <Html content={banner} />
            </li>
        );
    }
}

export default ProductCardBannerComponent;
