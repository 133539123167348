/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import { Fragment } from 'react';

import ProductCard from 'Component/ProductCard';
import ProductCardBanner from 'Component/ProductCardBanner';
import { PROMO_BANNER_GRID_POSITION, PROMO_BANNER_LIST_POSITION } from 'Component/ProductListPage/ProductListPage.config';
import { CategoryPageLayout } from 'Route/CategoryPage/CategoryPage.config';

import { ROOT, SEARCH } from '../component/GoogleTagManager/GoogleTagManager.config';
import { EVENT_GTM_IMPRESSIONS_PLP, EVENT_GTM_IMPRESSIONS_SEARCH } from '../component/GoogleTagManager/GoogleTagManager.events';

const state = (original) => ({
    ...original,
    lastSearch: '',
});

const renderItems = (args, callback, instance) => {
    const { items, event, isLoading } = instance.props;

    const { fired = false } = instance.state;

    const {
        location: { pathname },
    } = window;

    const isSearch = pathname.includes(SEARCH);

    if (isSearch && !fired && !isLoading) {
        instance.setState({ fired: true });
        const search = pathname.split(ROOT).pop();

        event(EVENT_GTM_IMPRESSIONS_SEARCH, {
            products: items,
            list: SEARCH,
            search,
        });
    }

    return callback(...args);
};

const renderPageItems = (args, callback, instance) => {
    const {
        items,
        selectedFilters,
        event,
        mix: { mods: { layout = CategoryPageLayout.GRID_LAYOUT } = {}, block = '' },
        tilePromoBanner,
        device,
    } = instance.props;
    const { fired = false } = instance.state;

    const {
        location: { pathname, search },
    } = window;

    const isSearch = pathname.includes(SEARCH);

    if (!isSearch) {
        if (!(Object.keys(items).length === 0) && !fired) {
            instance.setState({ fired: true });
            event(EVENT_GTM_IMPRESSIONS_PLP, {
                products: items,
                list: block,
                pathname,
                search,
            });
        }
    }

    return items.map((product, i) => {
        const position = layout === CategoryPageLayout.GRID_LAYOUT ? PROMO_BANNER_GRID_POSITION : PROMO_BANNER_LIST_POSITION;
        const shouldRenderInCurrentPosition = (items.length > position && i === position) || (items.length <= position && items.length === i + 1);
        const renderPromoBanner = shouldRenderInCurrentPosition && tilePromoBanner && !device.isTablet && !device.isMobile;

        return (
            <Fragment key={i}>
                {renderPromoBanner ? <ProductCardBanner banner={tilePromoBanner} key={`banner_${i}`} layout={layout} /> : null}
                <ProductCard
                    product={product}
                    // eslint-disable-next-line react/no-array-index-key

                    selectedFilters={selectedFilters}
                    layout={layout}
                    {...instance.containerProps()}
                />
            </Fragment>
        );
    });
};

export default {
    'PlugAndSell2/Component/ProductListPage/Component': {
        'member-function': {
            renderPageItems,
            renderItems,
        },
        'member-property': {
            state,
        },
    },
};
